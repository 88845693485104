import "./_productAdvantages.scss";
import Shield from "./../../assets/icons/section-shield.svg";
import Earth from "./../../assets/img/a-earth.png";
import Umbrella from "./../../assets/img/a-umbrella.png";
import Card from "./../../assets/img/a-card.png";
import Wallet from "./../../assets/img/a-wallet.png";



export const ProductAdvantages = ({ smoothDiv }) => {
    return (
        <>
            <div id="product-advantages-section" className="product-advantages-section c-section"  ref={smoothDiv}>
                <div className="container">
                    <div className="product-advantages-header">
                        <h2 className="section-title">Məhsulun üstünlükləri</h2>

                        <span className="shieldP" >    <img src={Shield} alt="" className="header-shield" /></span>

                    </div>
                    <div className="product-advantages-categories web">
                        <div className="product-advantage-category">
                            <div className="box">
                                <h4 className="title">Xərclər</h4>
                                <p className="text" >İşəgötürənlər üçün bədbəxt hadisənin baş verməsi nəticəsində maddi ödənişlərlə bağlı xərclərin olmaması</p>
                            </div>
                            <div className="img">
                                <img src={Wallet} alt="" />
                            </div>
                        </div>
                        <div className="product-advantage-category">
                            <div className="box">
                                <h4 className="title">Təminat</h4>
                                <p className="text" >Təminatlar yalnız ölkə ərazisində deyil bütün dünyada keçərlidir</p>
                            </div>
                            <div className="img">
                                <img src={Earth} alt="" />
                            </div>
                        </div>
                        <div className="product-advantage-category">
                            <div className="box">
                                <h4 className="title">Maddi müdafiə</h4>
                                <p className="text" >İşçinin və onun ailəsin əhəmiyyətli maddi müdafiəsi</p>
                            </div>
                            <div className="img">
                                <img src={Umbrella} alt="" />
                            </div>
                        </div>
                        <div className="product-advantage-category">
                            <div className="box">
                                <h4 className="title">Ödəniş</h4>
                                <p className="text" >Hissəvi ödəniş imkanı</p>
                            </div>
                            <div className="img">
                                <img src={Card} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="product-advantages-categories  mobile">
                        <div className="product-advantage-category">
                            <div className="box">
                                <img src={Wallet} alt="" />
                                <h4 className="title">Xərclər</h4>
                            </div>
                            <p className="text" >İşəgötürənlər üçün bədbəxt hadisənin baş verməsi nəticəsində maddi ödənişlərlə bağlı xərclərin olmaması</p>

                        </div>
                        <div className="product-advantage-category">
                            <div className="box">
                                <img src={Earth} alt="" />
                                <h4 className="title">Təminat</h4>
                            </div>
                            <p className="text" >Təminatlar yalnız ölkə ərazisində deyil bütün dünyada keçərlidir</p>

                        </div>
                        <div className="product-advantage-category">
                            <div className="box">
                                <img src={Umbrella} alt="" />
                                <h4 className="title">Maddi müdafiə</h4>
                            </div>
                            <p className="text" >İşçinin və onun ailəsin əhəmiyyətli maddi müdafiəsi</p>

                        </div>
                        <div className="product-advantage-category">
                            <div className="box">
                                <img src={Card} alt="" />
                                <h4 className="title">Ödəniş</h4>
                            </div>
                            <p className="text" >Hissəvi ödəniş imkanı</p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductAdvantages
