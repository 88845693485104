
import ReactTooltip from 'react-tooltip';
import "./_tariffs.scss";
import InfoIcon from "./../../assets/icons/info-icon.svg";

export const Tariffs = ({ smoothDiv }) => {
    return (
        <>
            <div id="tariffs-section" className="tariffs-section c-section" ref={smoothDiv}>
                <div className="container">
                    <div className="tariffs-header">
                        <h2 className="section-title">Tariflər</h2>
                    </div>
                    <div className="tariffs-categories">
                        <div className="tariffs-category">
                            <h4 className="title" >Qulluqçular: <span className="text" >illik əmək haqqı fondunun</span></h4>
                            <div className="bottom-info" >
                                <h2 className="tariffs-percent" >0.2% - 0.5%</h2>
                                <img src={InfoIcon} data-tip data-for='tarif1' alt="" />
                                <ReactTooltip id="tarif1" className="tooltip-dark" place="bottom" type="dark" effect="float" delayShow={200}>
                                    <p className="text">İllik sığorta haqqı sığorta olunanın bir illik əmək haqqı fondunun (aylıq əmək haqqının 12 misli) sığorta tarifinə hasili əsasında müəyyən edilir.
                                        Məs.: Neft sahəsində işləyən şöbə rəisinin (neft hasilatı, 14-cü risk dərəcəsi, qulluqçu kateqoriyası üçün tarif – 0,5%) sığorta haqqını hesablamaq üçün:
                                        1500 AZN (aylıq əmək haqqı) x 12 aya = 18000 AZN
                                        18000 AZN (illik əmək haqqı fondu) x 0,5% = 90 AZN sığorta haqqı</p>

                                </ReactTooltip>
                            </div>
                        </div>

                        <div className="tariffs-category">
                            <h4 className="title" >Fəhlələr: <span className="text" >illik əmək haqqı fondunun</span></h4>
                            <div className="bottom-info" >
                                <h2 className="tariffs-percent" >0.4% - 2.0%</h2>
                                <img src={InfoIcon} data-tip data-for='tarif2' alt="" />

                                <ReactTooltip id="tarif2" className="tooltip-dark" place="bottom" type="dark" effect="float" delayShow={200}>
                                    <p className="text">Oteldə ofisiantın (xidmət sektoru, 1-ci risk dərəcəsi, fəhlə kateqoriyası üçün tarif – 0,4%) sığorta haqqını hesablamaq üçün: 600 AZN (aylıq əmək haqqı) x 12 aya = 7200 AZN 7200 AZN (illik əmək haqqı fondu) x 0,4%= 28,8 AZN illik sığorta haqqı.
                                    </p>
                                </ReactTooltip>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Tariffs;
