
import "./_alertBox.scss";
import ErrorImg from "./../../../assets/img/error.png";
import AcceptImg from "./../../../assets/img/accept.png";

export const AlertBox = ({type,onClearState}) => {
    return (
        <div className="alert-box" >
        {type ==='success' ? (<>
            <img src={AcceptImg} alt="" />
        <h2 className="title" >Müraciətiniz qeydə alındı</h2>
        <p className="text">Əməkdaşlarımız sizinlə əlaqə saxlayacaq</p>
        <button className="btn" onClick = {onClearState}>Geri qayıt</button>
        </>) : (<>
            <img src={ErrorImg} alt="" />
        <h2 className="title" >Müraciətiniz qeydə alınmadı</h2>
        <p className="text">Zəhmət olmasa yenidən cəhd edin</p>
        <button className="btn" onClick = {onClearState}>Yenidən cəhd edin</button>
        </>

        )}
        
      </div>
    )
}


export default AlertBox;