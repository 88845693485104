
import { useState } from 'react';
import ModalVideo from 'react-modal-video'
import "./_videos.scss";
import 'react-modal-video/scss/modal-video.scss';

import PlayIcon from "./../../assets/icons/play-icon.svg";
import MüqaviləninHazırlanması from "./../../assets/img/Pasha-YouTube-MüqaviləninHazırlanması-2.png";
import İllikQoşmanınHazırlanması from "./../../assets/img/Pasha-YouTube-İllikQoşmanınHazırlanması-2.png";
import DigərİşçilərinQeydiyyatı from "./../../assets/img/Pasha-YouTube-DigərİşçilərinQeydiyyatı-2.png";
import HesabFakturanınƏldəEdilməsi from "./../../assets/img/Pasha-YouTube-HesabFakturanınƏldəEdilməsi-2.png";
// import GelirliHeyatSigortasi from "./../../assets/img/Pasha-YouTube-GelirliHeyatSigortasi-2.png";
// import İllikQoşmayaƏlavəninHazırlanması from "./../../assets/img/Pasha-YouTube-İllikQoşmayaƏlavəninHazırlanması-2.png";




import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 1800, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const leftArrow = (<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 13L1 7L7 1" stroke="#6C7880" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>)
const rightArrow = (<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 13L7 7L1 1" stroke="#6C7880" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>
)




export const Videos = ({ smoothDiv }) => {


    const [isOpen, setOpen] = useState(false);
    const [videoId, setVideoId] = useState('')

    const handleOpenVideo = (e, videoId) => {
        e.preventDefault();
        setVideoId(videoId);
        setOpen(true);

    }


 


    return (
        <>
            <div id="videos-section" className="videos-section c-section" ref={smoothDiv}>
                {console.log(smoothDiv)}
                <div className="container">
                    <div className="videos-header">
                        <h2 className="section-title">Video</h2>
                        <div className="tools">
                            <p className="text">İSB sistemindəki əməliyyatları aşağıdakı videolar ilə öyrənin</p>
                            <div className="arrows">
                                <span className="left arrow" >{leftArrow}</span>
                                <span className="right arrow" >{rightArrow}</span>

                            </div>

                        </div>
                    </div>
                    <div className="video-sliders">

                        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={3500}
                            removeArrowOnDeviceType={[ "tablet", "mobile"]}
                            draggable={false}
                            itemClass="carousel-item"
                        >

                            <div>
                                <div className="slider-img">
                                    <img src={MüqaviləninHazırlanması} alt="" />
                                    <a href="/" className="overlay" onClick={(e) => { handleOpenVideo(e, 'EEahBWrTuJo') }}>

                                         <img src={PlayIcon} alt="" />
                                    </a>
                                </div>
                                <p className="text">Müqavilənin hazırlanması</p>
                            </div>

                            <div>
                                <div className="slider-img">
                                    <img src={İllikQoşmanınHazırlanması} alt="" />
                                    <a href="/" className="overlay" onClick={(e) => { handleOpenVideo(e, 'Mo3aTENlde0') }}>
                                        <img src={PlayIcon} alt="" />
                                    </a>
                                </div>
                                <p className="text">İllik qoşmanın hazırlanması</p>
                            </div>
                            <div>
                                <div className="slider-img">
                                    <img src={DigərİşçilərinQeydiyyatı} alt="" />
                                    <a href="/" className="overlay" onClick={(e) => { handleOpenVideo(e, '_avWo5C3IAs') }}>
                                        <img src={PlayIcon} alt="" />
                                    </a>
                                </div>
                                <p className="text">Digər işçilərin qeydiyyatı</p>

                            </div>
                            <div>
                                <div className="slider-img">
                                    <img src={HesabFakturanınƏldəEdilməsi} alt="" />
                                    <a href="/" className="overlay" onClick={(e) => { handleOpenVideo(e, '_C_9JgVFmcE') }}>
                                        <img src={PlayIcon} alt="" />
                                    </a>
                                </div>
                                <p className="text">Hesab-fakturanın əldə edilməsi</p>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Videos;
