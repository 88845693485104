

import "./_productAbout.scss";
import Shield from "./../../assets/icons/shield.svg";
import People from "./../../assets/icons/people.svg";
import InfoSm from "./../../assets/icons/info-sm.svg";



export const ProductAbout = ({ smoothDiv }) => {
    return (
        <>
            <div id="product-about-section" className="product-about-section c-section" ref={smoothDiv}>
                <div className="container">
                    <div className="product-about-header">
                        <h2 className="section-title">“İcbari Həyat Sığorta” <br className="br-tablet" /> məhsulu <br className="br-web" />  haqqında</h2>
                        <p className="text" >İş yerində bədbəxt hadisə baş verdikdə bu, təkcə sizin üçün deyil, həm də işçiləriniz üçün əlavə xərcdir. Onları risklərdən qoruyub, “İcbari Həyat Sığortası”na bu gün qoşulun!</p>
                    </div>

                    <div className="product-about-categories">
                        <div className="product-about-category">
                            <div className="img" >
                                <img src={Shield} alt="" />
                            </div>
                            <div className="box">
                                <h4 className="title">Hansı təminatlar var?</h4>
                                <ul className="types" >
                                    <li className="type" >İstehsalatda bədbəxt hadisə və ya  peşə xəstəliyi nəticəsində ölüm;</li>
                                    <li className="type" >İstehsalatda bədbəxt hadisə və ya peşə xəstəlikləri nəticəsində peşə əmək qabiliyyətinin itirilməsi.</li>
                                </ul>
                                <div className="owner-alert">

                                    <div className="owner-img" >
                                        <img src={InfoSm} alt="" />
                                    </div>
                                    <div className="owner-content">
                                        <h4 className="owner-title" >Sahibkarların nəzərinə!</h4>
                                        <p className="text" >İcbari Həyat Sığortası olmadıq da, cərimə tətbiq olunur</p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="product-about-category">
                            <div className="img" >
                                <img src={People} alt="" />
                            </div>
                            <div className="box">
                                <h4 className="title">“İcbari Həyat Sığortası” kimlər üçündür?</h4>
                                <ul className="types" >
                                    <li className="type" >VÖEN-i olan fiziki şəxslər;</li>
                                    <li className="type" >Hüquqi şəxslər;</li>
                                    <li className="type" >Dövlət orqanları.</li>

                                </ul>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default ProductAbout;
