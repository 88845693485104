

import "./_header.scss";
import LogoWeb from "./../../assets/icons/header-logo-web.svg";
import LogoMobile from "./../../assets/icons/header-logo-mobile.svg";

import CallPhone from "./../../assets/icons/call-phone.svg";
import TopArrow from "./../../assets/icons/top-arrow.svg";

import { useState, useEffect } from "react";


export const Header = ({ smoothScroll, smoothDiv, onHandleModal }) => {


    const [scrollNumber, setScrollNumber] = useState(0);
    const [activeClassKey, setActiveClassKey] = useState(0);
    const handleClick = (elem, key) => {
        smoothScroll(smoothDiv[elem].current);
        // setActiveClassKey(key);
        const navElement = document.querySelector(
            `li[id="${key}"]`,
        )

        const menuItems = document.querySelectorAll('.menu-item');

        menuItems.forEach(item => {
            if(item.classList.contains('active')){
                item.classList.remove('active')
            }
        })
 
        if (!navElement.classList.contains('active')) {
            navElement.classList.add('active')

        } 
    }



        window.addEventListener("scroll", function () {
            setScrollNumber(window.scrollY);

        })


        useEffect(() => {
            let observer
            if (smoothDiv['smoothDiv1'].current && smoothDiv['smoothDiv2'].current && smoothDiv['smoothDiv3'].current && smoothDiv['smoothDiv4'].current && smoothDiv['smoothDiv5'].current) {
                const options = {
                    threshold: 0.5,
                }
                observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        // console.log(entry)
                        const navElement = document.querySelector(
                            `li[id="${entry.target.id}"]`,
                        )
                        const menuItems = document.querySelectorAll('.menu-item');

                        if (entry.isIntersecting) {
                            setActiveClassKey(entry.target.id);



                            menuItems.forEach(item => {
                                if(item.classList.contains('active')){
                                    item.classList.remove('active')
                                }
                            })
                    
                            if (!navElement.classList.contains('active')) {
                                navElement.classList.add('active')
                    
                            }
                        }else{
                            if (navElement.classList.contains('active')) {
                                navElement.classList.remove('active')
                    
                            }
                        }

                    })
                }, options)
                observer.observe(smoothDiv['smoothDiv1'].current)
                observer.observe(smoothDiv['smoothDiv2'].current)
                observer.observe(smoothDiv['smoothDiv3'].current)
                observer.observe(smoothDiv['smoothDiv4'].current)
                observer.observe(smoothDiv['smoothDiv5'].current)

            }
            return () => observer.disconnect()

        }, [smoothDiv])



        return (

            <>
                <header ref={smoothDiv['topSmoothDiv']}>

                    <div className="container header-container">
                        <div className={`header ${scrollNumber >= 5 && 'fixed-header'}`}>
                            <a href="/" >
                                <img className="logo-web" src={LogoWeb} alt="" />
                                <img className="logo-mobile" src={LogoMobile} alt="" />

                            </a>
                            <ul className="main-menu" >
                                <li id="product-about-section" className='menu-item' onClick={() => handleClick("smoothDiv1", 'product-about-section')} >Məhsul haqqında</li>
                                <li id="product-advantages-section" className='menu-item' onClick={() => handleClick("smoothDiv2", 'product-advantages-section')} >Üstünlüklər</li>
                                <li id="tariffs-section" className='menu-item' onClick={() => handleClick("smoothDiv3", 'tariffs-section')} >Tariflər</li>
                                <li id="videos-section" className='menu-item' onClick={() => handleClick("smoothDiv4", 'videos-section')} >Video</li>
                                <li id="faq-section" className='menu-item' onClick={() => handleClick("smoothDiv5", 'faq-section')} >Suallar</li>
                            </ul>
                            <div className="header-info-buttons" >
                                <a href="tel:012942" className="btn mr-1 web-btn">
                                    <img src={CallPhone} alt="" className="b-icon" />
                                    942
                                </a>
                                <button className="btn btn-primary" type="button" onClick={() => onHandleModal('call')} >Sizə zəng edək?</button>

                            </div>


                        </div>
                    </div>


                </header>

                {
                    scrollNumber >= 5 && <span className="top-arrow" onClick={() => handleClick("topSmoothDiv")} ><img src={TopArrow} alt="" /></span>
                }



            </>
        )
    }

    export default Header;