import MainPage from "./pages/MainPage";
import RouteList from "./routes";





export const App = () => {


  return (
    <>
      <MainPage />

    </>
  )
}


export default App;