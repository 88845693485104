

import { useState, useRef, useEffect } from "react";

import { YMInitializer as YM } from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import { Modal } from "react-responsive-modal";
import InputMask from 'react-input-mask';
import BeatLoader from "react-spinners/BeatLoader";

import FAQ from "./../../components/FAQ/FAQ";
import Footer from "./../../components/Footer/Footer";
import Header from "./../../components/Header/Header";
import InfoBanner from "./../../components/InfoBanner/InfoBanner";
import ISBBanner from "./../../components/ISBBanner/ISBBanner";
import MainBoard from "./../../components/MainBoard/MainBoard";
import ProductAbout from "./../../components/ProductAbout/ProductAbout";
import ProductAdvantages from "./../../components/ProductAdvantages/ProductAdvantages";
import Tariffs from "./../../components/Tariffs/Tariffs";
import Videos from "./../../components/Videos/Videos";
import AlertBox from "./../../components/UI/AlertBox/AlertBox";

import "react-responsive-modal/styles.css";

ReactGA.initialize('UA-33303280-9');
ReactGA.pageview(window.location.pathname);



const MainPage = () => {



    ReactPixel.init('332388651775430');
    ReactPixel.pageView();

    const tagManagerArgs = {
        gtmId: 'GTM-W4NQL3R'
    }
    TagManager.initialize(tagManagerArgs)

    //--------------------------------------- Smooth scroll-----------------------------------------
    const smoothDiv1 = useRef();
    const smoothDiv2 = useRef();
    const smoothDiv3 = useRef();
    const smoothDiv4 = useRef();
    const smoothDiv5 = useRef();
    const topSmoothDiv = useRef();


    const smoothObj = { smoothDiv1, smoothDiv2, smoothDiv3, smoothDiv4, smoothDiv5, topSmoothDiv };

    const smoothScroll = (target) => {
        const { top } = target.getBoundingClientRect();
        window.scrollTo({
            top: (top + window.pageYOffset) - 40,
            behavior: "smooth"
        });
    }

    //-------------------------------------------STATES-------------------------------------------------//
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const openModalUrl = window.location.pathname;

    const [infoData, setInfoData] = useState({
        name: '',
        number: ''
    });
    const { name, number } = infoData;
    const [valid, setValid] = useState({
        name: { isRequired: true },
        number: { isRequired: true }
    })

    const [alertStatus, setAlertStaus] = useState({
        type: '',
        isShow: false
    });
    const { type, isShow } = alertStatus;


    const clearState = () => {
        setValid({
            name: { isRequired: true },
            number: { isRequired: true }
        });
        setAlertStaus({
            isShow: false
        });

        setInfoData({ name: '', number: '' })

        setShowLoading(false);
    }



    //-------------------------------------------MODAL-------------------------------------------------//

    const handleOpenModal = (type) => {
        setOpenModal(true);
        setModalType(type);
        clearState()
    };

    const handleCloseModal = () => {
        setOpenModal(false);

        if (openModalUrl.includes("/sorgu")) {
            window.location.pathname = "/"
        }
    };


    const closeIcon = (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M2 2L16 16" stroke="#696F73" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 2L2 16" stroke="#696F73" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)



    //--------------------------------------- FORM -------------------------------------------//




    const handleChangeInput = (e) => {
        const targetName = e.target.name;
        setInfoData({
            ...infoData,
            [targetName]: e.target.value
        });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        setValid({
            name: { isRequired: name.trim() !== "" ? true : false },
            number: { isRequired: number !== "" ? true : false }
        });

        if (name.trim() !== "" && number !== "") {
            setShowLoading(true);

            const infoData = {
                fullName: name,
                number: number,
                RequestType: modalType !== "isb" ? 1 : 2
            }
            // const headers = new Headers();

            // headers.append('Content-Type', 'application/json');
            // headers.append('Accept', 'application/json');
            // headers.append('Access-Control-Allow-Methods','POST,OPTIONS,GET');
            // headers.append('Access-Control-Allow-Origin', '*');

            await fetch('https://soft.pasha-life.az/cpaagentapi/api/Company/post_icb', {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                method: 'POST',
                dataType: "json",
                body: JSON.stringify(infoData),
            })
                .then(res => {
                    if (res.status === 200) {
                        setAlertStaus({ type: "success", isShow: true });
                        setShowLoading(false);
                        modalType === "isb" && window.open("https://isb.az/", "_blank");

                    }
                })
                .catch(error => {
                    setAlertStaus({ type: "error", isShow: true });
                    console.log('Error', error)
                });

        }

    }

    const loader = (<div>Gözləyin <BeatLoader size="8" color={'white'} /></div>)

    useEffect(()=>{

    if (openModalUrl.includes("/sorgu")) {
        handleOpenModal()
    }

    },[])

    return (
        <>
            <YM
                accounts={[86932290]}
                options={
                    {
                        webvisor: true,
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                    }}
                version="2"

            />

            <Header smoothScroll={smoothScroll} smoothDiv={smoothObj} onHandleModal={handleOpenModal} />
            <MainBoard onHandleModal={handleOpenModal} />
            <ProductAbout smoothDiv={smoothDiv1} />
            <ProductAdvantages smoothDiv={smoothDiv2} />
            <Tariffs smoothDiv={smoothDiv3} />
            <Videos smoothDiv={smoothDiv4} />
            <ISBBanner onHandleModal={handleOpenModal} />
            <FAQ smoothDiv={smoothDiv5} />
            <InfoBanner onHandleModal={handleOpenModal} />
            <Footer />



            <Modal open={openModal} onClose={handleCloseModal} closeIcon={closeIcon} classNames={{ modal: "modal-content" }}>
                <div className="modal-header">
                    <h5 className="modal-title"> {modalType === "isb" ? 'İSB-ə Keçid' : 'Konsultasiya üçün müraciət'}</h5>
                </div>

                {isShow ? (<AlertBox type={type} onClearState={clearState} />) : (
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <p className="text" > {modalType === 'isb' ? 'Sizə daha yaxşı xidmət göstərməyimiz üçün adınızı və nömrənizi daxil edərək “İSB-yə Keçid” edə bilərsiniz.' : 'Ad və nömrənizi daxil edərək “Mənə zəng edin“ düyməsini sıxın. Qısa zaman ərzində əməkdaşlarımız sizinlə əlaqə saxlayacaq.'}</p>
                            <div className="form-content">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className={`form-group ${!valid.name.isRequired && 'invalid-form-group'}`}>
                                            <label>Adınız, soyadınız:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Adınız və soyadınızı daxil edin"
                                                name="name"
                                                value={name}
                                                onChange={handleChangeInput}
                                            />

                                            {!valid.name.isRequired && <p className="form-control-info-text" >Xananın doldurulması vacibdir!</p>}

                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className={`form-group ${!valid.number.isRequired && 'invalid-form-group'}`}>
                                            <label>Mobil nömrəniz:</label>

                                            <InputMask
                                                type="text"
                                                className="form-control"
                                                mask="+\9\94 (99) 999 99 99"
                                                placeholder="+994 (50) 000 00 00"
                                                name="number"
                                                value={number}
                                                onChange={handleChangeInput} />
                                            {!valid.number.isRequired && <p className="form-control-info-text" >Xananın doldurulması vacibdir!</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="btn btn-primary btn-lg " disabled={showLoading} type="submit">{showLoading ? loader : modalType === "call" ? "Mənə zəng edin" : 'Məlumat al'}</button>
                        </div>
                    </form>
                )}



            </Modal>
        </>
    )
}


export default MainPage;
