

import "./_infoBanner.scss";
import CallGirl  from "./../../assets/img/call-girl.png";
import LeftVektor  from "./../../assets/img/left-vektor.png";
import RightVektor  from "./../../assets/img/right-vector.png";







export const InfoBanner = (props) => {
    return (
        <>
        <div className="infoBanner-section">
            <div className="container">
                <div className="infoBanner-content">
                    <h2 className="infoBanner-text">Daha ətraflı məlumat almaq istəyirsinizsə.</h2>
                    <button className="btn btn-secondary btn-lg" onClick ={() => props.onHandleModal('call')} >Mənə zəng edin</button>
                    <img src={CallGirl} className="callGirl"  alt="" />
                  
                        <img src={LeftVektor} className="left-vector" alt="" />
                        <img src={RightVektor} className="right-vector" alt="" />


                 
                </div>

            </div>
        </div>
            
        </>
    ) 
}

export default InfoBanner;