
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./_mainBoard.scss";
import Worker1 from "./../../assets/icons/board-worker1.svg";
import Worker2 from "./../../assets/img/board-worker2.png";
import Worker3 from "./../../assets/img/board-worker3.png";

import CircleChart from "./../../assets/icons/circle-chart.svg";
import HysHand from "./../../assets/icons/hys-hand.svg";
import Diagram from "./../../assets/icons/diagram.svg";
import DiagramMobile from "./../../assets/icons/diagram-mobile.svg";

import DiagramCircle from "./../../assets/icons/diagram-circle.svg";




const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const MainBoard = (props) => {
    return (
        <>
            <div className="mainBoard-section" >
                <div className="container">
                    <div className="main-board-content">
                        <div className="info-part web">
                            <h1 className="title">Əməkdaşlarınızı <br className="title-br-1" /> “İcbari Həyat Sığortası” <br className="title-br" /> ilə təmin edin!</h1>
                            {/* <p className="text">Bu məhsulu əldə etmək üçün “İcbari Sığorta Bürosunun” saytına keçid edin.</p> */}
                            <button className="btn btn-primary btn-lg" onClick ={() => props.onHandleModal('info')}  >Məlumat al</button>
                        </div>
                        <div className="img-sliders" >
                            <Carousel
                                responsive={responsive}
                                infinite={true}
                                autoPlay={props.deviceType !== "mobile" ? true : false}
                                autoPlaySpeed={3500}
                                removeArrowOnDeviceType={["superLargeDesktop", "desktop", "tablet", "mobile"]}
                                draggable={true}


                            >
                                <div><img src={Worker2} alt="" /></div>
                                <div><img src={Worker3} alt="" /></div>

                                <div><img src={Worker1} alt="" /></div>

                            </Carousel>
                            <img src={Diagram} className="diagram-img web" alt="" />
                            <img src={DiagramMobile} className="diagram-img mobile" alt="" />
                            <img src={DiagramCircle} className="c1 circle" alt="" />
                            <img src={DiagramCircle} className="c2 circle" alt="" />
                            <img src={DiagramCircle} className="c3 circle" alt="" />

                            <div className="chart-box" >
                                <h2 className="info-text">Müvafiq məhsul üzrə bazar payımız</h2>
                                <img src={CircleChart} alt="" />

                            </div>


                        </div>
                        <div className="hand-box" >
                            <h2 className="info-text">İndividual xidmət</h2>
                            <img src={HysHand} alt="" />

                        </div>
                    </div>

                    <div className="info-part mobile">
                            <h1 className="title">Əməkdaşlarınızı <br className="title-br-1" /> “İcbari Həyat Sığortası” ilə <br className="title-br" /> təmin edin!</h1>
                            {/* <p className="text">Bu məhsulu əldə etmək üçün “İcbari Sığorta Bürosunun” saytına keçid edin.</p> */}
                            <button className="btn btn-primary" onClick ={() => props.onHandleModal('info')}  >Məlumat al</button>
                        </div>

                </div>
            </div>

        </>
    )
}

export default MainBoard;
