
import "./_footer.scss";
import FooterLogo from "./../../assets/icons/footer-logo-web.svg";
import Facebook from "./../../assets/icons/Facebook.svg";
import Instagram from "./../../assets/icons/Instagram.svg";
import Whatsapp from "./../../assets/icons/whatsapp.svg";
import Youtube from "./../../assets/icons/Youtube.svg";

export const Footer = () => {
    return (
        <>
            <div className="footer-section" >
                <div className="footer-top">
                    <div className="container">
                        <div className="footer-top-content">
                            <img src={FooterLogo} className="footer-logo" alt="" />

                            <div className="info-contents">
                                <div className="info-content">
                                    <h4 className="title" >Əlaqə</h4>
                                    <p className="text">(012) 942</p>
                                </div>
                                <div className="info-content">
                                    <h4 className="title" >Ünvan</h4>
                                    <p className="text mb-05">M.Useynov küç., 61</p>
                                    <p className="text">09:00 - 18:00</p>

                                </div>
                                <div className="info-content">
                                    <h4 className="title" >E-mail</h4>
                                    <p className="text">office@pasha-life.az</p>
                                </div>
                            </div>

                            <div className="social-icons mobile">
                                <a href="https://wa.me/+9940502251851" target="_blank" rel="noreferrer"> <img src={Whatsapp} alt="" />  </a>
                                <a href="https://www.facebook.com/pashalifeinsurance/" target="_blank" rel="noreferrer"> <img src={Facebook} alt="" />  </a>
                                <a href="https://www.instagram.com/pashalifeinsurance/" target="_blank" rel="noreferrer"> <img src={Instagram} alt="" />  </a>
                                <a href="https://www.youtube.com/channel/UCefcS2bG_DkaFUUi42MBtiA" target="_blank" rel="noreferrer"> <img src={Youtube} alt="" />  </a>

                            </div>
                        </div>
                    </div> 
                </div>
                <div className="divider"></div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="footer-bottom-content">
                            <p className="info-text">© {new Date().getFullYear()},  PAŞA Həyat Sığorta  ASC</p>
                            <div className="social-icons web">
                                <a href="https://wa.me/+9940502251851" target="_blank" rel="noreferrer" > <img src={Whatsapp} alt="" />  </a>
                                <a href="https://www.facebook.com/pashalifeinsurance/" target="_blank" rel="noreferrer"> <img src={Facebook} alt="" />  </a>
                                <a href="https://www.instagram.com/pashalifeinsurance/" target="_blank" rel="noreferrer"> <img src={Instagram} alt="" />  </a>
                                <a href="https://www.youtube.com/channel/UCefcS2bG_DkaFUUi42MBtiA" target="_blank" rel="noreferrer"> <img src={Youtube} alt="" />  </a>

                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </>
    )
} 

export default Footer;
