import "./_isbBanner.scss";

import ISB from "./../../assets/img/isb-kecid.png";

export const ISBBanner = (props) => {
    return (
        <>
        <div className="isb-section">
            <div className="container">
                <div className="isb-content">
                    <h2 className="isb-info-text">Bu məhsulu əldə etmək üçün  <span className="highlight">“İcbari Sığorta Bürosu"nun</span>  saytına keçid edin.</h2>
                    <div className="isb-info-buttons">
                        <img src={ISB} alt="" />
                        <button className="btn btn-primary btn-lg" onClick ={ () => props.onHandleModal('isb')} >"İSB"yə keçid</button>
                    </div>

                </div>

            </div>
        </div>

            
        </>
    )
}

export default ISBBanner;
 