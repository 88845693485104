
import { Accordion } from 'react-bootstrap-accordion';
import data from "./../../accordion-data.json";
import "./_faq.scss";

export const FAQ = ({smoothDiv}) => {
    return (
        <>
            <div id="faq-section" className="faq-section c-section" ref={smoothDiv}>
                <div className="container">
                    <div className="faq-header">
                        <h2 className="section-title">Ən çox verilən suallar</h2>
                    </div>
                    <div className="faq-accordions">

                        {
                            data.map((d, index) => <Accordion key={d.title + index} title={d.title} > {d.description} </Accordion>)

                         }

 

                    </div>

                </div>
            </div>
        </>
    )
}

export default FAQ; 